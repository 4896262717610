import { ElementType, ReactNode } from 'react';

import Text, { TextVariant } from '@/components/data-display/Text';

import { classNames } from '@/utils/classNames';

export interface CardinalProps {
  dataTestId?: string;
  headVariant?: TextVariant;
  bodyVariant?: TextVariant;
  head: ReactNode;
  headAs?: ElementType;
  body: ReactNode;
  bodyAs?: ElementType;
  headTransform?: string;
  bodyTransform?: string;
  bodyClassName?: string;
  headClassName?: string;
  orientation?: 'horizontal' | 'vertical';
  wrapperClassName?: string;
}

const Cardinal = ({
  dataTestId,
  headVariant,
  bodyVariant,
  head,
  headAs = 'span',
  body,
  bodyAs = 'span',
  headTransform,
  bodyTransform,
  bodyClassName,
  headClassName = 'text-sm font-semibold',
  orientation = 'vertical',
  wrapperClassName,
}: CardinalProps) => {
  const headClassNames = classNames(
    headClassName,
    headTransform || 'uppercase',
    'tracking-wider'
  );

  const bodyClassNames = classNames(
    bodyTransform,
    bodyClassName,
    'tracking-wide'
  );

  const wrapperClassNames = classNames(
    wrapperClassName,
    orientation === 'horizontal' ? 'flex items-center gap-2' : 'block'
  );

  return (
    <div className={wrapperClassNames}>
      <Text
        dataTestId={`${dataTestId}-head`}
        as={headAs}
        block
        variant={headVariant}
        className={headClassNames}
      >
        {head}
      </Text>
      <Text
        dataTestId={`${dataTestId}-body`}
        as={bodyAs}
        block
        variant={bodyVariant}
        className={bodyClassNames}
      >
        {body}
      </Text>
    </div>
  );
};

export default Cardinal;
