import moment from 'moment';

import { useEffect, useState, ReactNode } from 'react';

import Cardinal, { CardinalProps } from '@/components/data-display/Cardinal';

interface DateTimeCardinalProps extends Omit<CardinalProps, 'head' | 'body'> {
  autoUpdate?: boolean;
  bodyTimeFormat?: string;
  headTimeFormat?: string;
  head?: ReactNode;
  body?: ReactNode;
}

export const DateTimeCardinal = ({
  autoUpdate = true,
  body,
  bodyVariant = 'h2',
  bodyClassName = 'oldstyle-nums',
  bodyTimeFormat,
  head,
  headVariant,
  headClassName,
  headTimeFormat,
  ...rest
}: DateTimeCardinalProps) => {
  const [timeNow] = useState(new Date().toISOString());
  const [displayTime, setDisplayTime] = useState<string | moment.Moment>(
    timeNow
  );

  useEffect(() => {
    if (!autoUpdate) return undefined;

    const interval = setInterval(() => setDisplayTime(moment()), 1 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Cardinal
      head={
        head || moment(displayTime).format(bodyTimeFormat || 'dddd, MMMM D')
      }
      bodyVariant={bodyVariant}
      bodyClassName={bodyClassName}
      headVariant={headVariant}
      headClassName={headClassName || 'font-normal'}
      body={body || moment(displayTime).format(headTimeFormat || 'h:mm A')}
      {...rest}
    />
  );
};
