import throttle from 'lodash/throttle';
import moment from 'moment-timezone';

export const eventDurationOptions = [
  { label: '10 minutes', value: 10 },
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '45 minutes', value: 45 },
  { label: '1 hour', value: 60 },
];

export const eventTypeOptions = [
  { label: 'In-person', value: 2 },
  { label: 'Virtual', value: 1 },
];

const today = new Date();

export const weekday = new Array(7);
weekday[0] = 'sunday';
weekday[1] = 'monday';
weekday[2] = 'tuesday';
weekday[3] = 'wednesday';
weekday[4] = 'thursday';
weekday[5] = 'friday';
weekday[6] = 'saturday';

export const currentWDay = weekday[today.getDay()];

export const wdayToInteger = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  // eslint-disable-next-line sort-keys
  thursday: 4,
  // eslint-disable-next-line sort-keys
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export const toReadableTime = dateTime =>
  new Date(dateTime).toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
  });

export const isTimeOnly = timeString =>
  moment(timeString, 'HH:mm:ss.sss', true).isValid();

export const getTimeFromDateTime = isoString => {
  if (isTimeOnly(isoString)) return isoString;

  const sanitizedTime = isoString.split('T')[1].replace('Z', '');
  return sanitizedTime;
};

export const normalizeDateTimeFromParts = ({ date, time }) => {
  const dateTime = `${date}T${time}Z`;
  const dateTimeObject = new Date(dateTime);

  return { dateTime, dateTimeObject };
};

// @@@@

/**
 * Generate an array of timeIntervals based on timeInterval
 * Assumption for brevity:
 * - startTime and endTime are of valid time
 * - startTime will never be later than end time
 *
 * @param  {integer} timeInterval   In mins. Allowed values: 15, 30 or 60
 * @param  {string}  startTime      '03:45'. Min - '00:00', Max - '24:00'
 * @param  {string}  endTime        '15:00'. Min - '00:00', Max - '24:00'
 * @return {array}                  ['03:45', ....., '15:00']
 */

function genRange(start, stop, step) {
  const output = [] as any;
  let tmp = start;

  while (tmp + step <= stop) {
    output.push(tmp);
    tmp += step;
  }

  return output;
}

/**
 * Use it to convert time in HH:mm format to integer minutes
 *
 * @param {string} timeStr - time in `HH:mm` format
 *
 * @return {integer} - time in minutes
 *
 * @example
 *  strToMins('01:30') // 90
 *  strToMins('00:00') // 0
 *  strToMins('24:00') // 1440
 */

function strToMins(timeStr) {
  const [hours, minutes] = timeStr.split(':');

  return Number(hours) * 60 + Number(minutes);
}

/**
 * Use it to convert time in integer minutes to HH:mm format
 *
 * @param {integer} mins - time in minutes
 *
 * @return {string} - time in HH:mm format
 *
 * @example
 *  minsToStr(90) // '01:30'
 *  minsToStr(0) // '00:00'
 *  minsToStr(1440) // '24:00'
 */

function minsToStr(mins) {
  const hours = String(Math.floor(mins / 60)).padStart(2, '0');
  const minutes = String(mins % 60).padStart(2, '0');

  return `${hours}:${minutes}`;
}

/**
 *
 * @param String time - in `HH:mm` format, UTC time - NO OFFSET!!
 * @param String timezone to apply to UTC time provided as `time`
 * @returns
 */
export const addTimezoneToTime = ({ time = '00:00', timezone = '', date }) => {
  if (!time) throw new Error('time string is required');
  if (!timezone) throw new Error('timezone is required');

  const timeFillerPrefix = date || '2002-02-02';
  const dateTime = `${timeFillerPrefix}T${time}`;

  const utcMoment = moment.utc(dateTime);

  const tzStartMoment = utcMoment.tz(timezone, true);
  const tzStartDateTimeString = tzStartMoment.toISOString();

  const tzStartTime = tzStartDateTimeString.match(/\d\d:\d\d/)?.[0];

  return tzStartTime;
};

// Use when need to provide time slots based on the timezone.
export const generateTimeslots = ({
  increment = 10,
  startTime = '00:00', // we assume this to be UTC, so 0 offset.
  endTime = '00:00', // we assume this to be UTC, so 0 offset.
}) => {
  const isValidIncrement = eventDurationOptions.some(
    duration => duration.value === increment
  );
  if (!isValidIncrement) {
    return null;
  }
  const tzStartTime = startTime.match(/\d\d:\d\d/)?.[0];
  const tzEndTime = endTime.match(/\d\d:\d\d/)?.[0];

  const intStartTime = strToMins(tzStartTime);
  const intEndTime = strToMins(tzEndTime);

  const intStep = increment;

  const generated = genRange(intStartTime, intEndTime, intStep).map(minsToStr);

  return generated;
};

// Use when you need to generate time slots with the date
export const generateTimeslotsWithDate = ({
  date,
  increment = 10,
  startTime = '00:00', // we assume this to be UTC, so 0 offset.
  endTime = '00:00', // we assume this to be UTC, so 0 offset.
}) => {
  const isValidIncrement = eventDurationOptions.some(
    duration => duration.value === increment
  );
  if (!isValidIncrement) {
    return null;
  }
  if (!date) {
    return null;
  }
  const tzStartTime = startTime.match(/\d\d:\d\d/)?.[0];
  const tzEndTime = endTime.match(/\d\d:\d\d/)?.[0];

  const intStartTime = strToMins(tzStartTime);
  const intEndTime = strToMins(tzEndTime);

  const intStep = increment;

  const generated = genRange(intStartTime, intEndTime, intStep).map(minsToStr);

  return { date, generated };
};

// Takes in `react-select` option - an Object with a label and value
// and uses the value `HH:mm` to format GraphQL Time input string `HH:mm:ss.sss`
export const normalizeTimeInput = selected => {
  return `${selected.value}:00`;
};

export const applyOffsetToTime = (time, offset) => {
  const action = offset.toString().includes('-') ? 'subtract' : 'add';
  const normalizedTimeFromOffset = time.replace('+', '').replace('-', '');

  // const offsetTime = timeConvert(normalizedTimeFromOffset);

  if (action === 'add') {
    return minsToStr(strToMins(time) + strToMins(normalizedTimeFromOffset));
  }
  return minsToStr(strToMins(time) - strToMins(normalizedTimeFromOffset));
};

// Check if time string is in the format `HH:mm`
export const isHHmm = timeString => moment(timeString, 'HH:mm', true).isValid();
export const isHHmmss = timeString =>
  moment(timeString, 'HH:mm:ss', true).isValid();

// Function to remove seconds from HH:mm:ss
export const removeSeconds = time => {
  const hours = time.split(':')[0];
  const minutes = time.split(':')[1];

  return `${hours}:${minutes}`;
};

// returns `react-select` option - an Object with a label and value
export const isoTimeToReactSelectOption = timeString => {
  if (!timeString)
    throw new Error('Missing required params (time or timezone)');

  if (isHHmm(timeString)) {
    return { label: timeString, value: timeString };
  }

  if (isHHmmss(timeString))
    return { label: removeSeconds(timeString), value: timeString };

  throw new Error('Invalid time format, must be HH:mm or HH:mm:ss');
};

/**
 * Get the time in current timezone X hours from now
 */
export const getTimeXHoursFromNow = (hours = 1) => {
  const now = moment();

  return now.add(hours, 'hours').toISOString();
};

/**
 * Get the time in current timezone X hours from now
 */
export const getTimeXHoursAgo = (hours = 1) => {
  const now = moment();

  return now.subtract(hours, 'hours').toISOString();
};

export const throttledGetTimeXHoursAgo = throttle(
  getTimeXHoursAgo,
  5 * 60 * 1000 // 5 mins
);

// Check if the passed day of the week is yesterday or tomorrow in relation to the given day of the week

export const isYesterdayOrTomorrow = (currentDay, dayToCompare) => {
  const currentDayIndex = weekday.indexOf(currentDay.toLowerCase());
  const dayToCompareIndex = weekday.indexOf(dayToCompare.toLowerCase());

  if (currentDayIndex === -1 || dayToCompareIndex === -1) {
    throw new Error('Invalid day of the week');
  }

  if (currentDayIndex === dayToCompareIndex) {
    return 'today';
  }

  if (
    (currentDayIndex === 0 && dayToCompareIndex === 6) ||
    currentDayIndex - dayToCompareIndex === 1
  ) {
    return 'yesterday';
  }

  if (
    (currentDayIndex === 6 && dayToCompareIndex === 0) ||
    dayToCompareIndex - currentDayIndex === 1
  ) {
    return 'tomorrow';
  }

  return null;
};

export const isFirstWeekdayOfMonth = (weekday: number) => {
  const today = moment();

  if (today.day() !== weekday) {
    return false;
  }

  if (today.week() !== moment().startOf('month').week()) {
    return false;
  }

  return true;
};
