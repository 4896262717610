import { ComponentProps, ComponentType } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from '@/components/feedback/ErrorFallback';

type FallbackComponentProps = Omit<
  ComponentProps<typeof ErrorFallback>,
  'error'
> & {
  [key: string]: any;
};

type ErrorBoundaryOptions = {
  fallbackProps: FallbackComponentProps;
  FallbackComponent: ComponentType<any>;
};

const defaultErrorBoundaryOptions: ErrorBoundaryOptions = {
  FallbackComponent: ErrorFallback,
  fallbackProps: {},
};

export function wrapWithErrorBoundary<T extends object>(
  targetComponent: ComponentType<T>,
  errorBoundaryOptions = {}
) {
  const { FallbackComponent, fallbackProps, ...errorBoundaryProps } = {
    ...defaultErrorBoundaryOptions,
    ...errorBoundaryOptions,
  };

  return withErrorBoundary(targetComponent, {
    FallbackComponent: ({ error }) => (
      <FallbackComponent {...{ error, ...fallbackProps }} />
    ),
    ...errorBoundaryProps,
  }) as ComponentType<T>;
}
